import moment from "moment"

type SelectProps = {
  value: string
  onChange: (e: any) => void
  options: string[]
  defaultText?: string
  disabled?: boolean
}

const Select = ({
  value,
  onChange,
  options,
  defaultText,
  disabled,
}: SelectProps) => {
  return (
    <select
      className="bg-gray-100 w-full font-light pl-3 placeholder-black text-sm disabled:cursor-not-allowed disabled:text-gray-400"
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      <option value="">{defaultText ? defaultText : "Select an option"}</option>
      {options.map((time, index) => (
        <option key={index} value={time}>
          {moment(time).format("HH:mm")}
        </option>
      ))}
    </select>
  )
}

export default Select
