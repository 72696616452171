import { useEffect, useState, useCallback } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import { BookingBar, BabysitterCard } from "../components"
import axiosInstance from "../helpers/axios.instance"

const Search = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [babysitters, setBabysitters] = useState<any>([])
  const [address, setAddress] = useState<string>("")
  const [date, setDate] = useState<string>("")
  const [startTime, setStartTime] = useState<string>("")
  const [endTime, setEndTime] = useState<string>("")

  const fetchBabysitters = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `/babysitters?address=${address}&date=${date}&startTime=${startTime}&endTime=${endTime}`
      )

      setBabysitters(response.data)
    } catch (error) {
      console.log(error)
    }
  }, [address, date, endTime, startTime])

  useEffect(() => {
    fetchBabysitters()
  }, [address, date, endTime, fetchBabysitters, startTime])

  useEffect(() => {
    if (
      !searchParams.get("address") ||
      !searchParams.get("date") ||
      !searchParams.get("startTime") ||
      !searchParams.get("endTime")
    ) {
      navigate("/babysitters")
    }

    if (searchParams.get("address")) {
      setAddress(searchParams.get("address")!)
    }
    if (searchParams.get("date")) {
      setDate(searchParams.get("date")!)
    }
    if (searchParams.get("startTime")) {
      setStartTime(searchParams.get("startTime")!)
    }
    if (searchParams.get("endTime")) {
      setEndTime(searchParams.get("endTime")!)
    }
  }, [searchParams, navigate])

  return (
    <section className="h-screen w-screen flex flex-col lg:justify-center pt-32 lg:px-40 px-5">
      <h2 className="font-bold lg:text-4xl text-2xl pb-10">
        Available babysitters
      </h2>
      <div className="flex items-center gap-6">
        {babysitters?.map((babysitter: any) => (
          <BabysitterCard key={babysitter.id} babysitter={babysitter} />
        ))}
      </div>
      <BookingBar
        address={address}
        date={date}
        startTime={startTime}
        endTime={endTime}
      />
    </section>
  )
}

export default Search
