import { ReactNode } from "react"
import { Link } from "react-router-dom"

interface SectionProps {
  title: string
  text?: string
  children?: ReactNode
  buttonTitle: string
  buttonPath: string
  imageUrl: string
  reverse?: boolean
}

const Section = ({
  title,
  text,
  children,
  buttonTitle,
  buttonPath,
  imageUrl,
  reverse,
}: SectionProps) => {
  return (
    <section className="lg:p-20 px-5 py-20 grid lg:grid-cols-2 lg:gap-0 gap-10 items-center">
      <div className={`${reverse ? "order-last" : "order-first"}`}>
        <img src={imageUrl} alt="" />
      </div>
      <div className={`${reverse ? "order-first" : "order-last lg:px-14"}`}>
        <h2 className="font-bold lg:text-4xl text-2xl pb-5">{title}</h2>
        <p className="pb-10">{text}</p>
        {children}
        <Link to={buttonPath} className="bg-dark-pink py-2 px-6 rounded-md">
          <span className="text-white">{buttonTitle}</span>
        </Link>
      </div>
    </section>
  )
}

export default Section
