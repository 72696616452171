import React from 'react'
import { capitalize } from '../helpers/capitalize'

interface LabelProps {
    label: string
    variant?: 'primary' | 'secondary'
}

const Label = ({label, variant = 'primary'}: LabelProps) => {
  return (
    <span className="bg-dark-pink rounded-full text-sm py-1 px-3 text-white">{capitalize(label)}</span>
  )
}

export default Label