import { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Button, Loader } from "../components";
import LocationSearchInput from "./forms/LocationSearchInput";
import BookingModalContainer from "./booking-modal/BookingModalContainer";
import ModalHeader from "./booking-modal/ModalHeader";
import BookingModalInput from "./booking-modal/BookingModalInput";
import BookingModalTotal from "./booking-modal/BookingModalTotal";
import axiosInstance from "../helpers/axios.instance";
import { ClockIcon, CalendarIcon } from "@radix-ui/react-icons";
import { CreateBookingType } from "../types/booking-context.type";
import { BookingContext } from "./context";

interface BookingModalProps {
  setShowBookingModal: (value: boolean) => void;
  id?: string;
}

type AddressType = {
  street: string;
  suburb: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  latitude: string;
  longitude: string;
};

const BookingModal = ({ setShowBookingModal, id }: BookingModalProps) => {
  const navigate = useNavigate();

  const { addBooking } = useContext(BookingContext);

  const [loadingStartTimes, setLoadingStartTimes] = useState<boolean>(false);
  const [loadingEndTimes, setLoadingEndTimes] = useState<boolean>(false);
  const [loadingTotal, setLoadingTotal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [date, setDate] = useState<string>("");
  const [addressObject, setAddressObject] = useState<AddressType | null>(null);
  const [address, setAddress] = useState(null);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  const [startTimes, setStartTimes] = useState<string[]>(["8:00"]);
  const [endTimes, setEndTimes] = useState<string[]>([]);
  const [fullTimes, setFullTimes] = useState<string[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [travelCost, setTravelCost] = useState<number>(0);
  const [deposit, setDeposit] = useState<number>(0);

  const fetchStartTimes = async (date: any) => {
    setDate(date);
    setEndTimes([]);
    setEndTime("");
    setStartTime("");
    setStartTimes([]);
    setTotal(0);
    setTravelCost(0);
    setDeposit(0);
    setTotalHours(0);
    try {
      setLoadingStartTimes(true);
      const response = await axiosInstance.post("/bookings/start-times", {
        date: date,
        id: id,
      });
      setLoadingStartTimes(false);

      setStartTimes(response.data.availableStartTimes);
      setFullTimes(response.data.fullTimes);
    } catch (error) {
      setLoadingStartTimes(false);
    }
  };

  const fetchEndTimes = async (startTime: string) => {
    try {
      setLoadingEndTimes(true);
      const response = await axiosInstance.post("/bookings/end-times", {
        startTime,
        fullTimes,
      });
      setLoadingEndTimes(false);

      setEndTimes(response.data.availableEndTimes);
    } catch (error) {
      setLoadingEndTimes(false);
    }
  };

  const fetchTotal = useCallback(async () => {
    if (!startTime || !endTime || !address || !date) return;

    try {
      setLoadingTotal(true);
      const response = await axiosInstance.post("/bookings/total", {
        id,
        latitude: addressObject?.latitude,
        longitude: addressObject?.longitude,
        startTime: startTime,
        endTime: endTime,
      });
      setLoadingTotal(false);

      setTotal(response.data.total);
      setTravelCost(response.data.travelCost);
      setDeposit(response.data.deposit);
      setTotalHours(response.data.totalHours);
    } catch (error) {
      setLoadingTotal(false);
    }
  }, [startTime, endTime, address, date, id, addressObject]);

  const handleSubmit = async () => {
    // Set booking context and redirect to submit page
    if (!startTime || !endTime || !addressObject || !date || !id) return;
    setLoading(true);

    // Set booking context and redirect to submit page
    const booking: CreateBookingType = {
      babysitterId: +id,
      date: date,
      startTime: startTime,
      endTime: endTime,
      street: addressObject.street,
      suburb: addressObject.suburb,
      city: addressObject.city,
      province: addressObject.province,
      country: addressObject.country,
      postalCode: addressObject.postalCode,
      latitude: addressObject.latitude,
      longitude: addressObject.longitude,
      deposit: deposit,
      total: total,
      travelCost: travelCost,
      totalHours: totalHours,
    };

    addBooking(booking);
    navigate("/submit");
    setLoading(false);
  };

  useEffect(() => {
    if (startTime && endTime && addressObject && date) {
      fetchTotal();
    }
  }, [startTime, endTime, address, date, fetchTotal, addressObject]);

  return (
    <BookingModalContainer>
      <ModalHeader
        onClick={() => setShowBookingModal(false)}
        loading={loading}
        title="Select your date and times"
      />
      <LocationSearchInput
        value={address}
        onChange={setAddressObject}
        setState={setAddress}
      />
      <BookingModalInput Icon={<CalendarIcon className="h-5 w-5" />}>
        <input
          type="date"
          className="bg-gray-100 w-full pl-3 font-light placeholder-black text-sm"
          value={date}
          onChange={(e) => {
            fetchStartTimes(e.target.value);
          }}
        />
      </BookingModalInput>
      {loadingStartTimes ? (
        <Loader />
      ) : (
        date && (
          <BookingModalInput Icon={<ClockIcon className="h-5 w-5 mr-2" />}>
            <Select
              value={startTime}
              onChange={(e) => {
                setStartTime(e.target.value);
                fetchEndTimes(e.target.value);
              }}
              options={startTimes}
              defaultText="Start Time"
              disabled={date === ""}
            />
          </BookingModalInput>
        )
      )}
      {loadingEndTimes ? (
        <Loader />
      ) : (
        date &&
        startTime && (
          <BookingModalInput Icon={<ClockIcon className="h-5 w-5 mr-2" />}>
            <Select
              value={endTime}
              onChange={(e) => {
                setEndTime(e.target.value);
              }}
              options={endTimes}
              defaultText="End Time"
              disabled={date === ""}
            />
          </BookingModalInput>
        )
      )}
      <hr className="my-5" />
      <div className="flex lg:flex-row flex-col gap-5 lg:gap-0 items-center justify-around">
        <BookingModalTotal
          total={total}
          deposit={deposit}
          loading={loadingTotal}
        />
        <div className="w-full flex flex-col items-center justify-center px-5">
          <Button
            onClick={handleSubmit}
            text="Book"
            disabled={loading || !startTime || !endTime || !address || !date}
          />
          <p className="text-sm pt-1">
            {travelCost > 0 ? `Travel Cost: R${travelCost}` : ""}
          </p>
        </div>
      </div>
    </BookingModalContainer>
  );
};

export default BookingModal;
