import { BabysitterCard, Loader } from "../components"
import useFetch from "../hooks/useFetch"

const Babysitters = () => {
  const { data, loading } = useFetch({
    url: "/babysitters",
  })

  return (
    <section
      className={`px-5 py-24 lg:px-20 w-full ${
        loading && "grid place-items-center min-h-screen"
      }`}
    >
      {loading && <Loader />}
      <section className="grid lg:grid-cols-4 gap-10">
        {data?.map((babysitter: any) => (
          <BabysitterCard key={babysitter.id} babysitter={babysitter} />
        ))}
      </section>
    </section>
  )
}

export default Babysitters
