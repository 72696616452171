import StepItem from "../StepItem"

const HowItWorks = () => {
  const steps = [
    {
      number: "1",
      text: "Browse & select babysitter",
    },
    {
      number: "2",
      text: "Choose date & times",
    },
    {
      number: "3",
      text: "Pay 30% deposit",
    },
    {
      number: "4",
      text: "Enjoy a stress-free babysitting experience",
    },
    {
      number: "5",
      text: "Rate your experience",
    },
    {
      number: "6",
      text: "Pay final invoice",
    },
  ]

  return (
    <section className="py-10 lg:pt-20 lg:pb-10 lg:my-20 my-10 lg:px-20 px-5 mx-3 bg-light-gray rounded-lg">
      <h2 className="font-bold text-2xl lg:text-4xl pb-5">How it works</h2>
      <p className="lg:w-3/4">
        We understand the importance of balancing parenthood with busy
        schedules, and that's why we've created a seamless and secure platform
        that connects parents with experienced and trustworthy babysitters.
      </p>
      <div className="grid grid-cols-1 lg:grid-cols-6 lg:pt-20 py-10 lg:gap-5 gap-3">
        {steps.map((step: any, index: number) => (
          <>
            <StepItem number={step.number} text={step.text} />
            {index !== steps.length - 1 && (
              <div className="lg:hidden h-8 border-black border-l lg:w-0 lg:border-l-0 ml-4"></div>
            )}
          </>
        ))}
      </div>
    </section>
  )
}

export default HowItWorks
