import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const useProtectedRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";

    if (!isLoggedIn) {
      navigate(`/sign-in?redirect=${location.pathname}`);
    }
  }, [navigate, location.pathname]);

  return;
};

export default useProtectedRoute;
