import { HomeIcon } from "@radix-ui/react-icons"
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete"

type LocationSearchInputProps = {
  value: any
  onChange: (address: any) => void
  setState: (address: any) => void
}

type AddressType = {
  street: string
  suburb: string
  city: string
  province: string
  country: string
  postalCode: string
  latitude: string
  longitude: string
}

const LocationSearchInput = ({
  value,
  onChange,
  setState,
}: LocationSearchInputProps) => {
  const handleAddressChange = async (selectedAddress: any) => {
    setState(selectedAddress)

    const placeId = selectedAddress.value.place_id
    const geocodedAddress = await geocodeByPlaceId(placeId)
    const lat = geocodedAddress[0].geometry.location.lat()
    const lng = geocodedAddress[0].geometry.location.lng()
    const addressNameArr = selectedAddress.label.split(",")

    const addressObject: AddressType = {
      street: addressNameArr[0].trim(),
      suburb: addressNameArr[1].trim(),
      city: addressNameArr[2].trim(),
      province: geocodedAddress[0].address_components[5].long_name.trim(),
      country: addressNameArr[3].trim(),
      postalCode: geocodedAddress[0].address_components[7].long_name.trim(),
      latitude: lat.toString(),
      longitude: lng.toString(),
    }

    onChange(addressObject)
  }

  return (
    <div className="bg-gray-100 p-2 rounded-md flex items-center lg:col-span-3 w-full">
      <HomeIcon className="w-4 h-4" />
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        apiOptions={{ language: "en", region: "za" }}
        selectProps={{
          value: value,
          onChange: (address) => handleAddressChange(address),
          className:
            "bg-gray-100 w-full pl-3 font-light placeholder-black text-sm",
          placeholder: "Start typing your address...",
        }}
      />
    </div>
  )
}

export default LocationSearchInput
