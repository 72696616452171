import { useContext } from "react"
import { HelmetContext } from "./context/helmet"
import { Helmet } from "react-helmet"

const PageHelmet = () => {
  const { title } = useContext(HelmetContext)

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}

export default PageHelmet
