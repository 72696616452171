import { useNavigate } from "react-router-dom"
import { FaArrowLeft } from "react-icons/fa6"

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <button
      onClick={() => navigate(-1)}
      className="flex items-center gap-3 border-b-transparent border-b hover:border-b hover:border-b-black transition-all duration-200 ease-in-out"
    >
      <FaArrowLeft />
      <span className="text-sm">Go back</span>
    </button>
  )
}

export default BackButton
