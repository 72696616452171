import { Link } from "react-router-dom"
import Label from "../Label"

type BookingRowProps = {
  id: number
  imageUrl: string
  name: string
  date: string
  startTime: string
  endTime: string
  status: string
}

const BookingRow = ({
  id,
  imageUrl,
  name,
  date,
  startTime,
  endTime,
  status,
}: BookingRowProps) => {
  return (
    <div className="grid grid-cols-4 text-center items-center border-t border-b py-3">
      <div className="flex items-center gap-5">
        <div className="bg-primary-pink w-12 ratio-square rounded-full drop-shadow grid place-items-center">
          <img
            src={imageUrl}
            alt={name}
            className="ratio-square rounded-full object-cover h-12"
          />
        </div>
        <h4 className="font-medium">{name}</h4>
      </div>
      <div className="flex items-center gap-2 justify-center">
        <h4>{date}</h4>
        <h4>
          {startTime}-{endTime}
        </h4>
      </div>
      <div>
        <Label
          label={status}
        />
      </div>
      <Link to={`/bookings/${id}`} className="text-darker-pink">
        View booking
      </Link>
    </div>
  )
}

export default BookingRow
