import { HomeIcon, CalendarIcon, ClockIcon } from "@radix-ui/react-icons"

interface BookingBarProps {
  address?: string
  date?: string
  startTime?: string
  endTime?: string
}

const BookingBar = ({ address, date, startTime, endTime }: BookingBarProps) => {
  return (
    <div className="absolute left-0 bottom-0 w-full lg:px-40 px-5 py-10">
      <div className="bg-white rounded-md drop-shadow-lg grid lg:grid-cols-12 items-center gap-3 px-7 py-5 z-50">
        <div className="bg-gray-100 p-2 rounded-md flex items-center lg:col-span-4">
          <HomeIcon className="h-5 w-5" />
          <input
            type="text"
            className="bg-gray-100 w-full pl-3 font-light placeholder-black"
            placeholder="Address"
            value={address}
          />
        </div>
        <div className="bg-gray-100 p-2 rounded-md flex items-center lg:col-span-3">
          <CalendarIcon className="h-5 w-5" />
          <input
            type="date"
            className="bg-gray-100 w-full pl-3 font-light placeholder-black"
            value={date}
          />
        </div>
        <div className="bg-gray-100 p-2 rounded-md flex items-center lg:col-span-3">
          <div>
            <ClockIcon className="h-5 w-5" />
          </div>
          <input
            type="text"
            className="bg-gray-100 w-full border-r-2 border-r-gray-300 text-center font-light placeholder-black"
            placeholder="Start"
            value={startTime}
          />
          <input
            type="text"
            className="bg-gray-100 w-full text-center font-light placeholder-black"
            placeholder="End"
            value={endTime}
          />
        </div>
        <button className="bg-dark-pink lg:col-span-2 rounded-md py-2 hover:bg-darker-pink transition-all ease-in-out duration-300">
          <span className="text-white">Search</span>
        </button>
      </div>
    </div>
  )
}

export default BookingBar
