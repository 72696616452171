type ButtonProps = {
  type?: "submit" | "button" | "reset"
  text: string
  variant?: "primary" | "secondary"
  onClick?: () => void
  disabled?: boolean
}

const Button = ({
  type = "button",
  text,
  variant = "primary",
  onClick,
  disabled = false,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`
        ${
          variant === "primary"
            ? "bg-dark-pink text-white hover:bg-darker-pink"
            : "bg-white text-black"
        }
        rounded-full py-2  transition-all ease-in-out duration-30 w-full disabled:bg-gray-300 disabled:cursor-not-allowed`}
      onClick={onClick}
    >
      <span>{text}</span>
    </button>
  )
}

export default Button
