type BookingModalTotalProps = {
  total: number
  deposit: number
  loading: boolean
}

const BookingModalTotal = ({
  total,
  deposit,
  loading,
}: BookingModalTotalProps) => {
  return (
    <div className="border-r-gray-300 lg:border-r w-full flex items-center justify-around">
      {loading ? (
        <div className="text-center">
          <p className="font-semibold text-dark-pink">Calculating...</p>
        </div>
      ) : (
        <>
          <div className="text-center">
            <p className="text-gray-500 font-semibold text-xs">TOTAL</p>
            <p className="font-semibold text-xl ">
              {total > 0 ? `R${total}` : "-"}
            </p>
          </div>
          <div className="text-center">
            <p className="text-gray-500 font-semibold text-xs">
              What You Pay Now
            </p>
            <p className="font-semibold text-xl">
              {deposit > 0 ? `R${deposit}` : "-"}
            </p>
          </div>
        </>
      )}
    </div>
  )
}

export default BookingModalTotal
