import { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { MyBookings, MyDetails } from "../components"

type Tab = "bookings" | "address" | "details"

const Profile = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeTab, setActiveTab] = useState<Tab>("bookings")

  const tabs = [
    {
      name: "bookings",
      label: "My Bookings",
    },
    {
      name: "details",
      label: "My Details",
    },
    {
      name: "address",
      label: "My Address Book",
    },
  ]

  useEffect(() => {
    const tab = searchParams.get("tab") as Tab
    if (tab) {
      setActiveTab(tab)
    }
  }, [searchParams])

  return (
    <section className="py-24 lg:px-20 px-5">
      <h1 className="text-3xl font-semibold pb-7">My Account</h1>
      <div className="grid grid-cols-12">
        <aside className="col-span-2 pr-5">
          <ul className="flex flex-col gap-3">
            {tabs.map((tab, index) => (
              <li
                key={index}
                className={`${
                  activeTab === tab.name && "bg-primary-pink"
                } py-1 pl-2 text-sm font-semibold rounded text-gray-600`}
              >
                <button
                  className="w-full text-left"
                  onClick={() => {
                    setSearchParams({ tab: tab.name })
                    setActiveTab(tab.name as Tab)
                  }}
                >
                  {tab.label}
                </button>
              </li>
            ))}
          </ul>
        </aside>
        <div className="col-span-10 drop-shadow w-full h-full bg-white px-10 py-10 rounded">
          {activeTab === "bookings" && <MyBookings />}
          {activeTab === "details" && <MyDetails />}
        </div>
      </div>
    </section>
  )
}

export default Profile
