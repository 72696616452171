import { useEffect, useCallback, useState } from "react"
import useEmblaCarousel, { EmblaOptionsType } from "embla-carousel-react"
import BabysitterCard from "./babysitters/BabysitterCard"
import Loader from "./Loader"
import useFetch from "../hooks/useFetch"
import { FaCircleChevronRight, FaCircleChevronLeft } from "react-icons/fa6"

interface BabySitterSliderProps {
  options?: EmblaOptionsType
}

const BabysitterSlider = ({ options }: BabySitterSliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const { data, loading } = useFetch({
    url: "/babysitters/featured",
  })

  const [emblaRef, emblaApi] = useEmblaCarousel({
    slidesToScroll: 1,
    align: "start",
    ...options,
  })

  const nextSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  const prevSlide = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi: any, eventName: any) => {
    const index = emblaApi.selectedScrollSnap()
    setCurrentIndex(index)
  }, [])

  useEffect(() => {
    if (emblaApi) {
      const index = emblaApi.selectedScrollSnap()
      setCurrentIndex(index)
      emblaApi.on("select", onSelect)
    }
  }, [emblaApi, onSelect])

  return (
    <section className="pt-20 pb-5 lg:py-20">
      <div className="flex items-center justify-between pb-10 px-5 lg:px-20">
        <h2 className="font-bold lg:text-4xl text-2xl">Top babysitters</h2>
        <button className="bg-dark-pink py-2 px-6 rounded-md">
          <span className="text-white text-sm">View all</span>
        </button>
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="w-screen relative">
            <div className="overflow-hidden" ref={emblaRef}>
              <div className="flex gap-10">
                {data?.map((babysitter: any, index: number) => (
                  <div
                    key={babysitter.id}
                    className={`flex-[0_0_90%] md:flex-[0_0_25%] ${
                      index === data.length - 1
                        ? "lg:mr-20 pr-10 lg:pr-0 pl-0"
                        : "lg:mx-0 px-0"
                    }
                        ${
                          index === 0
                            ? "lg:ml-20 pl-10 lg:pl-0"
                            : "lg:mx-0 pl-10 lg:pl-0"
                        }
                    `}
                  >
                    <BabysitterCard babysitter={babysitter} />
                  </div>
                ))}
              </div>
            </div>
            <button
              className="absolute left-5 top-0 bottom-0 m-auto -mt-10 invisible lg:visible"
              onClick={prevSlide}
            >
              <FaCircleChevronLeft className="w-8 h-8 text-gray-600" />
            </button>
            <button
              className="absolute right-8 top-0 bottom-0 m-auto -mt-10 invisible lg:visible"
              onClick={nextSlide}
            >
              <FaCircleChevronRight className="w-8 h-8 text-gray-600" />
            </button>
          </div>
          <div className="flex items-center justify-center gap-2 lg:pt-5">
            {data.map((_: any, index: number) => (
              <div
                key={index}
                className={`w-2 h-2 rounded-full
                    ${index === currentIndex ? "bg-gray-800" : "bg-gray-400"}
                `}
              ></div>
            ))}
          </div>
        </>
      )}
    </section>
  )
}

export default BabysitterSlider
