import { useState, useEffect } from "react"

const useIsTop = () => {
  const [top, setTop] = useState<boolean>(true)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < 50) {
        setTop(true)
      } else {
        setTop(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return top
}

export default useIsTop
