import { PropsWithChildren } from "react";

const BookingModalContainer = ({ children }: PropsWithChildren) => {
  return (
    <div className="cursor-default fixed w-screen h-screen top-0 left-0 flex items-center justify-center bg-gray-600 bg-opacity-60 z-50">
      <div className="bg-white lg:w-3/5 rounded-lg p-10 flex flex-col gap-5">
        {children}
      </div>
    </div>
  );
};

export default BookingModalContainer;
