import { Cross1Icon } from "@radix-ui/react-icons"

type ModalHeaderProps = {
  onClick: () => void
  loading: boolean
  title: string
}

const ModalHeader = ({ onClick, loading, title }: ModalHeaderProps) => {
  return (
    <div className="pb-5 flex items-center justify-between">
      <h2 className="font-bold lg:text-2xl text-xl pr-10">{title}</h2>
      <button
        onClick={onClick}
        disabled={loading}
        className="disabled:cursor-not-allowed disabled:opacity-50"
      >
        <Cross1Icon className="w-5 h-5 cursor-pointer" />
      </button>
    </div>
  )
}

export default ModalHeader
