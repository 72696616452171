import { useEffect, useState, useCallback } from "react"
import axiosInstance from "../helpers/axios.instance"
import { errorToast } from "../helpers/toast.helper"

const useFetch = ({ url }: { url: string }) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<any>(null)

  const fetchData = useCallback(async () => {
    try {
      const response = await axiosInstance.get(url)
      setData(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      errorToast("Error fetching data")
    }
  }, [url])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return {
    loading,
    data,
  }
}

export default useFetch
