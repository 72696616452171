import { useContext, useEffect } from "react"
import { HelmetContext } from "../components/context/helmet"

const useSetPageHelmet = ({ pageTitle }: { pageTitle: string }) => {
  const { setTitle, title } = useContext(HelmetContext)

  useEffect(() => {
    setTitle(pageTitle)
  }, [pageTitle, setTitle])

  return {
    title,
  }
}

export default useSetPageHelmet
