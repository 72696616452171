import { useState, useEffect } from "react";
import { Formik } from "formik";
import {
  myDetailsSchema,
  myPasswordSchema,
} from "../../validation-schemas/profile.schema";
import { Button, ProfileInput, Loader } from "../index";
import useFetch from "../../hooks/useFetch";
import axiosInstance from "../../helpers/axios.instance";

const MyDetails = () => {
  const [details, setDetails] = useState<any>(null);
  const [updatingDetails, setUpdatingDetails] = useState<boolean>(false);

  const { data, loading: loadingDetails } = useFetch({
    url: "/clients/me",
  });

  const handleDetailsChange = async (values: any) => {
    try {
      setUpdatingDetails(true);

      const res = await axiosInstance.patch("/clients", values);

      setDetails(res.data);
      setUpdatingDetails(false);
    } catch (error) {
      console.log("ERROR", error);
      setUpdatingDetails(false);
    }
  };

  const handlePasswordChange = async (values: any) => {
    console.log("UPDATING PASSSWORD");
  };

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  return (
    <>
      <h2 className="pb-8 text-2xl font-semibold">My Details</h2>
      {loadingDetails ? (
        <Loader />
      ) : (
        details && (
          <>
            <div>
              <h3 className="pb-2 font-semibold">Personal Information</h3>
              <hr />
              <div className="grid grid-cols-3 gap-10 py-5">
                <div className="col-span-1">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Commodi sunt autem sequi porro laboriosam accusantium, sint
                    explicabo earum nostrum nihil.
                  </p>
                </div>
                <div className="col-span-2">
                  <Formik
                    initialValues={{
                      firstname: details.firstname,
                      lastname: details.lastname,
                    }}
                    validationSchema={myDetailsSchema}
                    onSubmit={handleDetailsChange}
                  >
                    {({ handleSubmit, isSubmitting }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="flex flex-col gap-4"
                      >
                        {updatingDetails ? (
                          <Loader />
                        ) : (
                          <>
                            <div className="flex items-center gap-4">
                              <ProfileInput
                                type="text"
                                name="firstname"
                                label="First Name"
                                placeholder="John"
                              />
                              <ProfileInput
                                type="text"
                                name="lastname"
                                label="Last Name"
                                placeholder="Doe"
                              />
                            </div>
                            <Button
                              text="Save"
                              disabled={isSubmitting}
                              type="submit"
                            />
                          </>
                        )}
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div>
              <h3 className="pb-2 font-semibold">Email Address</h3>
              <hr />
              <div className="grid grid-cols-3 gap-10 py-5">
                <div className="col-span-1">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Commodi sunt autem sequi porro laboriosam accusantium, sint
                    explicabo earum nostrum nihil.
                  </p>
                </div>
                <div className="col-span-2">
                  <Formik
                    initialValues={{
                      email: details.user?.email,
                    }}
                    validationSchema={myDetailsSchema}
                    onSubmit={handleDetailsChange}
                  >
                    {({ handleSubmit, isSubmitting }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="flex flex-col gap-4"
                      >
                        <ProfileInput
                          type="email"
                          name="email"
                          label="Email Address"
                          placeholder="john@example.com"
                        />
                        <Button text="Save" disabled={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            <div className="pt-10">
              <h3 className="pb-2 font-semibold">Password</h3>
              <hr />
              <div className="grid grid-cols-3 gap-10 py-5">
                <div className="col-span-1">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Commodi sunt autem sequi porro laboriosam accusantium, sint
                    explicabo earum nostrum nihil.
                  </p>
                </div>
                <div className="col-span-2">
                  <Formik
                    initialValues={{
                      currentPassword: "",
                      newPassword: "",
                      confirmPassword: "",
                    }}
                    validationSchema={myPasswordSchema}
                    onSubmit={handlePasswordChange}
                  >
                    {({ handleSubmit, isSubmitting }) => (
                      <form
                        onSubmit={handleSubmit}
                        className="flex flex-col gap-4"
                      >
                        <ProfileInput
                          type="password"
                          name="currentPassword"
                          label="Current Password"
                          placeholder="Your current password"
                        />
                        <ProfileInput
                          type="password"
                          name="newPassword"
                          label="New Password"
                          placeholder="Your new password"
                        />
                        <ProfileInput
                          type="password"
                          name="confirmPassword"
                          label="Confirm new Password"
                          placeholder="Confirm your new password"
                        />
                        <Button text="Save" disabled={isSubmitting} />
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default MyDetails;
