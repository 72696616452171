import * as Yup from "yup"

export const signupSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "Passwords must match"
  ),
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
})
