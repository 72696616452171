interface StepItemProps {
  number: string
  text: string
}

const StepItem = ({ number, text }: StepItemProps) => {
  return (
    <div className="flex items-center gap-7 lg:flex-col lg:items-center lg:gap-3 lg:text-center">
      <div className="aspect-square rounded-full border border-black w-8 grid place-items-center">
        <span>{number}</span>
      </div>
      <p>{text}</p>
    </div>
  )
}

export default StepItem
