import services from "../data/services";
import { Accordion, Input, TextArea, Button } from "../components";
import { Formik } from "formik";
import { contactSchema } from "../validation-schemas/contact.schema";
import { FaEnvelope, FaPerson } from "react-icons/fa6";

const Services = () => {
  const handleSubmit = async (values: any) => {
    console.log(values);
  };

  return (
    <section>
      <div className="h-[60vh] grid place-items-center lg:pt-10 pt-32">
        <div className="flex items-center lg:justify-between justify-center overflow-hidden h-full lg:flex-row flex-col gap-10">
          <div className="flex flex-col lg:items-start items-center justify-center">
            <h1 className="lg:text-6xl text-4xl font-bold pb-3">
              Additional Services
            </h1>
            <p className="w-4/5 lg:text-xl lg:text-left text-center font-light">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde,
              adipisci?
            </p>
          </div>
          <img
            src="/hero.png"
            alt=""
            className="object-contain min-h-0 h-4/5"
          />
        </div>
      </div>
      <div className="lg:px-20 px-5 py-10 w-full flex flex-col items-center">
        <div className="flex flex-col gap-3 py-10 w-full">
          {services.map((service, index) => (
            <Accordion
              key={index}
              title={service.title}
              content={service.content}
            />
          ))}
        </div>
        <div className="lg;w-8/12 w-full py-10">
          <h2 className="text-2xl font-semibold pb-5">
            Contact us for a personalised quote
          </h2>
          <Formik
            initialValues={{
              name: "",
              email: "",
              service: "",
              message: "",
            }}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form
                className="w-full flex flex-col gap-3"
                onSubmit={handleSubmit}
              >
                <Input
                  name="name"
                  type="text"
                  placeholder="Your Name"
                  Icon={<FaPerson className="h-5 w-5" />}
                />
                <Input
                  name="email"
                  type="email"
                  placeholder="Your Email Address"
                  Icon={<FaEnvelope className="h-5 w-5" />}
                />
                <Input
                  name="service"
                  type="Service"
                  placeholder="Service"
                  Icon={<FaEnvelope className="h-5 w-5" />}
                />
                <TextArea name="message" placeholder="Message" />
                <Button
                  type="submit"
                  text="Login"
                  variant="primary"
                  disabled={isSubmitting}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </section>
  );
};

export default Services;
