const About = () => {
  return (
    <section>
      <div className="h-[60vh] grid place-items-center lg:pt-10 pt-32">
        <div className="flex items-center lg:justify-between justify-center overflow-hidden h-full lg:flex-row flex-col gap-10">
          <div className="flex flex-col lg:items-start items-center justify-center">
            <h1 className="lg:text-6xl text-4xl font-bold pb-3">Our Story</h1>
            <p className="w-4/5 lg:text-xl lg:text-left text-center font-light">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde,
              adipisci?
            </p>
          </div>
          <img
            src="/hero.png"
            alt=""
            className="object-contain min-h-0 h-4/5"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
