import { createContext, useState, ReactNode, useEffect } from "react"
import {
  BookingContextState,
  BookingType,
} from "../../types/booking-context.type"

const defaultValues: BookingContextState = {
  booking: null,
  addBooking: () => {},
  clearBooking: () => {},
}

export const BookingContext = createContext<BookingContextState>(defaultValues)

const BookingContextProvider = ({ children }: { children: ReactNode }) => {
  const [booking, setBooking] = useState<BookingType | null>(
    defaultValues.booking
  )

  const addBooking = (booking: BookingType) => setBooking(booking)

  const clearBooking = () => setBooking(null)

  useEffect(() => {
    const booking = localStorage.getItem("booking")

    if (booking) {
      setBooking(JSON.parse(booking))
    }
  }, [])

  return (
    <BookingContext.Provider
      value={{
        booking,
        addBooking,
        clearBooking,
      }}
    >
      {children}
    </BookingContext.Provider>
  )
}

export default BookingContextProvider
