import BookingRow from "./BookingRow";
import Loader from "../Loader";
import useFetch from "../../hooks/useFetch";
import { BookingType } from "../../types/booking.types";

const MyBookings = () => {
  const { data: bookings, loading: loadingBookings } = useFetch({
    url: "/bookings/client",
  });

  return (
    <>
      <h2 className="pb-8 text-2xl font-semibold">My Bookings</h2>
      <div className="w-full flex flex-col gap-3">
        <div className="grid grid-cols-4 text-center items-center">
          <h4 className="font-medium text-left">Babysitter</h4>
          <h4 className="font-medium">Date</h4>
          <h4 className="font-medium">Status</h4>
          <h4 className="font-medium">Actions</h4>
        </div>
        {loadingBookings ? (
          <Loader />
        ) : (
          bookings?.map((booking: BookingType) => (
            <BookingRow
              key={booking.id}
              id={booking.id}
              imageUrl={booking.babysitterImage}
              name={booking.babysitter}
              date={booking.date}
              startTime={booking.startTime}
              endTime={booking.endTime}
              status={booking.status}
            />
          ))
        )}
      </div>
    </>
  );
};

export default MyBookings;
