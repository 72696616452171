import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { StarFilledIcon, ArrowDownIcon } from "@radix-ui/react-icons"
import { BookingModal, BabysitterTabs, Loader } from "../components"
import useFetch from "../hooks/useFetch"

const Babysitter = () => {
  const { id } = useParams()
  const [showBookingModal, setShowBookingModal] = useState<boolean>(false)

  const { data, loading } = useFetch({
    url: `/babysitters/${id}`,
  })

  useEffect(() => {
    if (showBookingModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [showBookingModal])

  return (
    <section>
      {showBookingModal && (
        <BookingModal setShowBookingModal={setShowBookingModal} id={id} />
      )}
      <div className="h-screen w-screen flex lg:flex-row flex-col items-center justify-center bg-primary-pink">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="absolute bottom-0 animate-bounce left-1/2">
              <ArrowDownIcon className="w-8 h-8 " />
            </div>
            <div className="lg:pt-24 pt-10 w-full lg:h-screen flex items-center justify-center">
              <img
                src={data.imageUrl}
                alt=""
                className="object-contain h-full"
              />
            </div>
            <div className="w-full flex flex-col items-center justify-center lg:p-10 p-5 lg:m-0 -mt-44">
              <div className="bg-white w-full rounded-lg px-10">
                <div className="flex items-center justify-between pt-5 lg:pt-10">
                  <h1 className="font-bold text-2xl lg:text-3xl">
                    {data.firstname}
                  </h1>
                  <p className="flex items-center gap-2">
                    <span className="text-sm">{data.rating}</span>
                    <StarFilledIcon className="w-4 h-4 text-yellow-400" />
                  </p>
                </div>
                <p className="font-light text-sm pb-5 text-gray-600">
                  {data.suburb}, {data.province}
                </p>
                <p className="pb-5 lg:pb-10 font-light">
                  {data.shortDescription}
                </p>
                <button
                  className="bg-dark-pink w-full py-2 rounded-full hover:bg-darker-pink transition all ease-in-out duration-200 lg:mb-10 mb-5"
                  onClick={() => setShowBookingModal(true)}
                >
                  <span className="text-white">Book</span>
                </button>
                <hr />
                <div className="flex items-center justify-around py-3">
                  <div className="border-r-gray-300 border-r w-full flex flex-col items-center justify-center">
                    <p className="text-gray-500 font-semibold text-xs">
                      CLIENTS
                    </p>
                    <p className="font-semibold text-xl">{data.totalClients}</p>
                  </div>
                  <div className=" w-full flex flex-col items-center justify-center">
                    <p className="text-gray-500 font-semibold text-xs">HOURS</p>
                    <p className="font-semibold text-xl">{data.totalHours}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {!loading && (
        <BabysitterTabs description={data.description} reviews={data.reviews} />
      )}
    </section>
  )
}

export default Babysitter
