import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";
import parse from "html-react-parser";

interface AccordionProps {
  title: string;
  content: string;
}

const Accordion = ({ title, content }: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <button
        onClick={() => setOpen(!open)}
        className={`border-b p-3 flex items-center justify-between w-full ${
          open ? "bg-light-gray" : "bg-white"
        }`}
      >
        <span>{title}</span>
        {open ? <FaChevronUp /> : <FaChevronDown />}
      </button>
      <div
        className={`${
          open ? "h-auto opacity-100" : "hidden h-0 opacity-0"
        } transition-all duration-200 ease-in-out p-3 bg-light-gray`}
      >
        <p>{parse(content)}</p>
      </div>
    </div>
  );
};

export default Accordion;
