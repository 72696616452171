import { Hero, HowItWorks, Section, BabysitterSlider } from "../components"
import useSetPageHelmet from "../hooks/useSetPageHelmet"

const Home = () => {
  useSetPageHelmet({ pageTitle: "Welcome | Goobies Babysitting" })

  return (
    <section>
      <Hero />
      <HowItWorks />
      <BabysitterSlider />
      <Section
        title="Why choose us"
        text="The Goobies Babysitting Team is made up of carefully selected babysitters. To ensure competency, we personally trained each member of our crew. Our secret to success lies in our babysitter’s ability to build relationships with the children, by spending quality time with them through fun games and activities."
        buttonTitle="Read more"
        buttonPath="/about"
        imageUrl="/why-choose-us.png"
      >
        <div className="flex items-center pb-10 gap-20">
          <div>
            <h3 className="font-semibold text-3xl flex items-center gap-2">
              665+<span className="font-extralight text-xl">hours</span>
            </h3>
            <p className="font-light text-sm">Spent doing what we love</p>
          </div>
          <div>
            <h3 className="font-semibold text-3xl flex items-center gap-2">
              65<span className="font-extralight text-xl">clients</span>
            </h3>
            <p className="font-light text-sm">Satisfied &amp; impressed</p>
          </div>
        </div>
      </Section>
      <Section
        title="Additional services"
        text="We also specialise in the following services:"
        buttonTitle="Read more"
        buttonPath="/services"
        imageUrl="/services.png"
        reverse
      >
        <ul className="pb-10 flex flex-col gap-5 text-lg font-light">
          <li>
            <span>Babysitting at events</span>
          </li>
          <li>
            <span>Overnight Sitter</span>
          </li>
          <li>
            <span>Flat Rate Services</span>
          </li>
        </ul>
      </Section>
    </section>
  )
}

export default Home
