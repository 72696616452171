import { Outlet, useLocation } from "react-router-dom"
import {
  Navbar,
  Footer,
  BookingContextProvider,
  HelmetContextProvider,
  PageHelmet,
} from "../components"
import useScrollToTop from "../hooks/useScrollToTop"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const BaseLayout = () => {
  useScrollToTop()
  const location = useLocation()

  const dontShowNavigation = [
    "/sign-in",
    "/sign-up",
    "/forgot-password",
    "/reset-password",
  ]

  return (
    <main className="overflow-hidden">
      <HelmetContextProvider>
        <BookingContextProvider>
          <PageHelmet />
          <ToastContainer />
          {dontShowNavigation.includes(location.pathname) ? null : <Navbar />}
          <Outlet />
          {dontShowNavigation.includes(location.pathname) ? null : <Footer />}
        </BookingContextProvider>
      </HelmetContextProvider>
    </main>
  )
}

export default BaseLayout
