import { toast, ToastOptions } from "react-toastify"

const options: ToastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}

export const errorToast = (message: string) => {
  toast.error(message, options)
}

export const successToast = (message: string) => {
  toast.success(message, options)
}
