import { ReactNode, useState } from "react"
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons"
import { useField, FieldHookConfig } from "formik"

type InputProps = {
  type: string
  placeholder: string
  Icon: ReactNode
}

const Input = ({
  type,
  placeholder,
  Icon,
  ...props
}: InputProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <div>
      <div className="bg-gray-100 p-2 rounded-md flex items-center">
        {Icon && Icon}
        <input
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          {...field}
          className="bg-gray-100 w-full pl-3 font-light placeholder-black text-sm placeholder:text-sm focus:outline-none"
          placeholder={placeholder}
        />
        {type === "password" && (
          <button
            className="bg-gray-100 rounded-full px-1.5 hover:bg-gray-200 transition-all ease-in-out duration-300"
            onClick={() => setShowPassword(!showPassword)}
            type="button"
          >
            {showPassword ? (
              <EyeOpenIcon className="h-4 w-4" />
            ) : (
              <EyeClosedIcon className="h-4 w-4" />
            )}
          </button>
        )}
      </div>
      {meta.touched && meta.error ? (
        <span className="text-xs text-red-500 pt-1 pl-1">
          <sup>*</sup>
          {meta.error}
        </span>
      ) : (
        <span className="error">&nbsp;</span>
      )}
    </div>
  )
}

export default Input
