const services = [
    {
        title: 'Flat Rate Services',
        content: 'Do you need a babysitter from 6am to 5pm?<br/>During school holidays?<br/>Or to take care of a sick child?<br/><br/>Then our flat rate services are perfect for you!'
    },
    {
        title: 'Overnight Babysitting Service',
        content: 'Are you going away for the weekend and need someone to take care of your kids?<br/>Do you need to attend a two day business conference?<br/><br/>Let us take care of your little ones, day and night!'
    },
    {
        title: 'Babysitting at Events',
        content: 'Need an expert to entertain the children at your next party, event or corporate function?<br/><br/>Let us know what you have in mind, and we will send you a quotation!'
    }
]

export default services