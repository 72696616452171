import { ReactNode } from "react"

type BookingModalInputProps = {
  children: ReactNode
  Icon: ReactNode
}

const BookingModalInput = ({ children, Icon }: BookingModalInputProps) => {
  return (
    <div className="bg-gray-100 p-2 rounded-md flex items-center lg:col-span-3 w-full">
      {Icon}
      {children}
    </div>
  )
}

export default BookingModalInput
