import { createContext, ReactNode, useState } from "react"
import { HelmetContextType } from "../../../types/helmet.types"

export const HelmetContext = createContext<HelmetContextType>({
  title: "",
  setTitle: () => {},
})

interface Props {
  children: ReactNode
}

const HelmetContextProvider = ({ children }: Props) => {
  const [title, setTitle] = useState<string>("")

  const handleSetTitle = (title: string) => setTitle(title)

  return (
    <HelmetContext.Provider
      value={{
        title,
        setTitle: handleSetTitle,
      }}
    >
      {children}
    </HelmetContext.Provider>
  )
}

export default HelmetContextProvider
