import BookingBar from "../BookingBar"

const Hero = () => {
  return (
    <section className="w-screen h-screen flex items-start pt-32 lg:pt-0 lg:items-center justify-start">
      <div className="flex lg:flex-row flex-col lg:items-center lg:justify-start z-10 lg:gap-0 gap-10">
        <div className="lg:pl-20 pl-5">
          <h1 className="font-bold lg:text-6xl text-4xl lg:pb-10 pb-3">
            Your Ultimate
            <br />
            Babysitting Crew
          </h1>
          <p className="lg:text-xl font-light">
            Welcome to Goobies Babysitting, here
            <br /> to provide seamless and secure childcare.
          </p>
        </div>
        <div className="lg:absolute lg:right-0">
          <img src="/hero.png" alt="" />
        </div>
        <BookingBar />
      </div>
    </section>
  )
}

export default Hero
