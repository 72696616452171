import { Input, Button, TextArea } from "../components"
import { contactSchema } from "../validation-schemas/contact.schema"
import { Formik } from "formik"
import axiosInstance from "../helpers/axios.instance"
import { PersonIcon } from "@radix-ui/react-icons"
import { errorToast, successToast } from "../helpers/toast.helper"

const Contact = () => {
  const handleSubmit = async (values: any) => {
    try {
      await axiosInstance.post("/contact", values)

      successToast("Message sent successfully!")
      values.name = ""
      values.email = ""
      values.message = ""
    } catch (error) {
      errorToast("Something went wrong!")
    }
  }

  return (
    <section className="p-20 h-screen w-screen">
      <div className="flex items-center w-full h-full">
        <div className="w-full h-full flex flex-col items-center justify-center">
          <h1 className="font-bold text-center pb-8 text-xl">Contact Us</h1>
          <Formik
            initialValues={{
              name: "",
              email: "",
              message: "",
            }}
            validationSchema={contactSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form
                className="w-2/3 flex flex-col gap-3"
                onSubmit={handleSubmit}
              >
                <Input
                  name="name"
                  type="text"
                  placeholder="Name"
                  Icon={<PersonIcon className="h-4 w-4" />}
                />
                <Input
                  name="email"
                  type="email"
                  placeholder="Email"
                  Icon={<PersonIcon className="h-4 w-4" />}
                />
                <TextArea name="message" />
                <Button
                  type="submit"
                  text="Send Message"
                  variant="primary"
                  disabled={isSubmitting}
                />
              </form>
            )}
          </Formik>
        </div>
        <div className="w-full h-full flex items-center justify-center">
          <img src="/hero-image.webp" alt="" />
        </div>
      </div>
    </section>
  )
}

export default Contact
