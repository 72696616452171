import { useState, useMemo, useEffect } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import AccountTooltip from "./AccountTooltip";
import MobileMenu from "./MobileMenu";
import { Cross as Hamburger } from "hamburger-react";
import axiosInstance from "../../helpers/axios.instance";
import useIsTop from "../../hooks/useIsTop";

interface LinkType {
  id: number;
  name: string;
  path: string;
}

const Navbar = () => {
  const top = useIsTop();
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const links = useMemo(() => {
    const links: LinkType[] = [
      {
        id: 1,
        name: "Babysitters",
        path: "/babysitters",
      },
      {
        id: 2,
        name: "About Us",
        path: "/about",
      },
      {
        id: 3,
        name: "Services",
        path: "/services",
      },
      {
        id: 4,
        name: "Contact Us",
        path: "/contact",
      },
    ];
    return links;
  }, []);

  useEffect(() => {
    const loggedIn = sessionStorage.getItem("isLoggedIn");
    if (loggedIn) {
      setIsLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location]);

  const handleLogout = async () => {
    await axiosInstance.post("/auth/sign-out", {});
    sessionStorage.removeItem("isLoggedIn");
    navigate("/");
  };

  return (
    <nav
      className={`fixed top-0 left-0 w-full py-3 z-50 bg-white ${
        top ? "lg:bg-transparent" : "lg:bg-white/50"
      }`}
    >
      <div className="flex items-center justify-between w-full lg:px-20 px-5">
        <div>
          <Link
            to="/"
            className="flex gap-1 font-bold text-gray-700 items-center "
          >
            <img src="/goobies-logo.png" alt="" />
          </Link>
        </div>
        <div className="hidden lg:flex items-center gap-8 ">
          {links.map((link) => (
            <NavLink
              key={link.id}
              to={link.path}
              className={({ isActive }) => (isActive ? "underline" : "")}
            >
              {link.name}
            </NavLink>
          ))}
          <AccountTooltip handleLogout={handleLogout} isLoggedIn={isLoggedIn} />
        </div>
        <div className="flex gap-6 lg:hidden">
          <div className="flex items-center">
            <Hamburger
              toggled={mobileMenuOpen}
              toggle={setMobileMenuOpen}
              size={23}
            />
          </div>
        </div>
      </div>
      <MobileMenu
        mobileMenuOpen={mobileMenuOpen}
        handleLogout={handleLogout}
        links={links}
        isLoggedIn={isLoggedIn}
      />
    </nav>
  );
};

export default Navbar;
