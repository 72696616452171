import React from "react"
import ReactDOM from "react-dom/client"
import "./globals.css"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import BaseLayout from "./layouts/BaseLayout"
import {
  Home,
  Babysitters,
  Babysitter,
  Search,
  About,
  Services,
  Contact,
  Login,
  SignupPage,
  Checkout,
  Profile,
  Submit,
  BookingDetails,
} from "./routes"

const router = createBrowserRouter([
  {
    path: "/",
    element: <BaseLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/babysitters",
        element: <Babysitters />,
      },
      {
        path: "/babysitters/:id",
        element: <Babysitter />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/sign-in",
        element: <Login />,
      },
      {
        path: "/sign-up",
        element: <SignupPage />,
      },
      {
        path: "/checkout/:id",
        element: <Checkout />,
      },
      {
        path: "/submit",
        element: <Submit />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/bookings/:id",
        element: <BookingDetails />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<RouterProvider router={router} />)
