import {
  PersonIcon,
  LockClosedIcon,
  EnvelopeClosedIcon,
} from "@radix-ui/react-icons"
import { Link } from "react-router-dom"
import { Input, Button, BackButton } from "../components"
import { Formik } from "formik"
import { signupSchema } from "../validation-schemas/signup.schema"
import axiosInstance from "../helpers/axios.instance"
import { useNavigate, useSearchParams } from "react-router-dom"
import { errorToast } from "../helpers/toast.helper"

const SignupPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleSubmit = async (values: any) => {
    try {
      await axiosInstance.post("/clients", {
        email: values.email,
        password: values.password,
        firstname: values.firstname,
        lastname: values.lastname,
      })

      navigate(searchParams.get("redirect") || "/")
    } catch (error) {
      errorToast("Something went wrong!")
    }
  }

  return (
    <section className="h-screen w-screen flex items-center justify-center">
      <div className="absolute top-5 left-5 z-50">
        <BackButton />
      </div>
      <div className="absolute top-0 left-0">
        <img src="/hero-bg.webp" alt="" />
      </div>
      <div className="bg-white drop-shadow-lg w-2/6 h-4/5 rounded-lg p-10 flex items-center flex-col">
        <img src="/goobies-logo.png" alt="" className="pb-5" />
        <div className="pb-8">
          <h1 className="font-bold text-center pb-2 text-xl">Welcome</h1>
          <p className="text-sm">Enter your details below to get started</p>
        </div>
        <Formik
          initialValues={{
            email: "",
            password: "",
            firstname: "",
            lastname: "",
            confirmPassword: "",
          }}
          validationSchema={signupSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form
              className="w-full flex flex-col gap-3"
              onSubmit={handleSubmit}
            >
              <div className="flex items-center gap-3">
                <Input
                  name="firstname"
                  type="text"
                  placeholder="First Name"
                  Icon={<PersonIcon className="h-4 w-4" />}
                />
                <Input
                  name="lastname"
                  type="text"
                  placeholder="Last Name"
                  Icon={<PersonIcon className="h-4 w-4" />}
                />
              </div>
              <Input
                name="email"
                type="email"
                placeholder="Enter Email"
                Icon={<EnvelopeClosedIcon className="h-4 w-4" />}
              />
              <Input
                name="password"
                type="password"
                placeholder="Enter Password"
                Icon={<LockClosedIcon className="h-4 w-4" />}
              />
              <Input
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                Icon={<LockClosedIcon className="h-4 w-4" />}
              />
              <Button
                type="submit"
                text="Login"
                variant="primary"
                disabled={isSubmitting}
              />
            </form>
          )}
        </Formik>
        <div className="flex items-center justify-center w-full pt-5 gap-5">
          <Link to="/login" className="text-sm">
            Already have an account?
          </Link>
        </div>
      </div>
    </section>
  )
}

export default SignupPage
