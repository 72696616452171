import { useField, FieldHookConfig } from "formik"

const TextArea = ({ ...props }: FieldHookConfig<string>) => {
  const [field, meta] = useField(props)

  return (
    <div>
      <textarea
        {...field}
        cols={30}
        rows={5}
        className="w-full bg-gray-100 rounded-md px-5 py-3 resize-none focus:outline-none text-sm placeholder:text-sm"
        placeholder="Message"
      ></textarea>
      {meta.touched && meta.error ? (
        <span className="text-xs text-red-500 pt-1 pl-1">
          <sup>*</sup>
          {meta.error}
        </span>
      ) : (
        <span className="error">&nbsp;</span>
      )}
    </div>
  )
}

export default TextArea
