import { Link } from "react-router-dom";

interface LinkType {
  id: number;
  name?: string;
  path: string;
}

interface MobileMenuProps {
  mobileMenuOpen: boolean;
  handleLogout: () => void;
  links: LinkType[];
  isLoggedIn: boolean;
}

const MobileMenu = ({
  mobileMenuOpen,
  handleLogout,
  links,
  isLoggedIn,
}: MobileMenuProps) => {
  return (
    <div
      className={`fixed z-40 w-full  bg-white overflow-hidden flex flex-col lg:hidden gap-10 origin-top duration-700 ${
        !mobileMenuOpen ? "h-0" : "h-full shadow-lg"
      }`}
    >
      <div className="pt-10">
        <div className="flex flex-col gap-5 text-xl font-semibold">
          {links.map((link, index) => (
            <div key={index}>
              <div className="pl-8">
                <Link key={link.id} to={link.path}>
                  {link.name}
                </Link>
              </div>
              <hr />
            </div>
          ))}
        </div>
      </div>
      <div className="px-8">
        <div className="flex flex-col gap-5 text-xl font-semibold">
          {isLoggedIn ? (
            <>
              <Link to="/profile?tab=bookings">My Bookings</Link>
              <Link to="/profile?tab=details">My Details</Link>
              <button
                className="flex items-center gap-2"
                onClick={handleLogout}
              >
                Sign Out
              </button>
            </>
          ) : (
            <>
              <Link to="/sign-in">Sign In</Link>
              <Link to="/sign-up">Sign Up</Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
