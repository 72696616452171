import { StarFilledIcon } from "@radix-ui/react-icons"
import { Link } from "react-router-dom"

interface BabysitterCardProps {
  babysitter: {
    id: string
    firstname: string
    rating: number
    imageUrl: string
  }
}

const BabysitterCard = ({
  babysitter: { id, firstname, rating, imageUrl },
}: BabysitterCardProps) => {
  return (
    <Link
      to={`/babysitters/${id}`}
      className="flex flex-col items-center justify-between bg-white drop-shadow-md rounded-md mb-5"
    >
      <div className="flex justify-center bg-primary-pink pt-4 px-4 rounded-t-md w-full">
        <img
          src={imageUrl}
          alt=""
          className="object-contain aspect-square w-60 h-60"
        />
      </div>
      <div className="bg-white z-10 w-full py-4 px-6 rounded-b-md flex flex-col gap-1">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-bold">{firstname}</h3>
          <p className="flex items-center gap-1 text-xs">
            <span>{rating}</span>
            <StarFilledIcon className="inline-block w-3 h-3 text-yellow-500" />
          </p>
        </div>
        <p className="text-sm">R95/hour</p>
      </div>
    </Link>
  )
}

export default BabysitterCard
