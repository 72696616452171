import React from "react";

const Footer = () => {
  return (
    <footer className="relative grid place-items-center p-20 border-t border-t-black">
      <img
        src="/footer.png"
        alt=""
        className="absolute bottom-0 right-0 object-contain h-full"
      />
      <div className="grid grid-cols-3 w-full z-20">
        <div className="flex flex-col items-center justify-center">
          <h4>Quick links</h4>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h4>Quick links</h4>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h4>Quick links</h4>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
