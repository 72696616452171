import { useState } from "react"
import { EyeOpenIcon, EyeClosedIcon } from "@radix-ui/react-icons"
import { useField, FieldHookConfig } from "formik"

type ProfileInputProps = {
  placeholder: string
  label: string
  type: string
}

const ProfileInput = ({
  placeholder,
  label,
  type,
  ...props
}: ProfileInputProps & FieldHookConfig<string>) => {
  const [field, meta] = useField(props)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  return (
    <div className="w-full">
      <label htmlFor={field.name} className="text-sm font-medium">
        {label}
      </label>
      <div className="bg-gray-100 p-2 rounded-md flex items-center">
        <input
          {...field}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          className="rounded bg-gray-100 w-full pl-3 font-light placeholder-black text-sm placeholder:text-sm"
          placeholder={placeholder}
        />
        {type === "password" && (
          <button
            className="bg-gray-100 rounded-full p-1.5 hover:bg-gray-200 transition-all ease-in-out duration-300"
            onClick={() => setShowPassword(!showPassword)}
            type="button"
          >
            {showPassword ? (
              <EyeOpenIcon className="h-4 w-4" />
            ) : (
              <EyeClosedIcon className="h-4 w-4" />
            )}
          </button>
        )}
      </div>
      {meta.touched && meta.error ? (
        <span className="text-xs text-red-500 pt-1 pl-1">
          <sup>*</sup>
          {meta.error}
        </span>
      ) : (
        <span className="error">&nbsp;</span>
      )}
    </div>
  )
}

export default ProfileInput
