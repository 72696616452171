import { PersonIcon, LockClosedIcon } from "@radix-ui/react-icons"
import { Link } from "react-router-dom"
import { Input, Button, BackButton } from "../components"
import { Formik } from "formik"
import { loginSchema } from "../validation-schemas/login.schema"
import axiosInstance from "../helpers/axios.instance"
import { useNavigate, useSearchParams } from "react-router-dom"
import { errorToast } from "../helpers/toast.helper"

const Login = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleSubmit = async (values: any) => {
    try {
      await axiosInstance.post("/auth/sign-in", {
        email: values.email,
        password: values.password,
      })

      sessionStorage.setItem("isLoggedIn", "true")
      navigate(searchParams.get("redirect") || "/")
    } catch (error) {
      errorToast("Something went wrong!")
    }
  }

  return (
    <section className="h-screen w-screen flex items-center justify-center">
      <div className="absolute top-5 left-5 z-50">
        <BackButton />
      </div>
      <div className="absolute top-0 left-0 h-screen">
        <img src="/hero-bg.webp" alt="" />
      </div>
      <div className="bg-white drop-shadow-lg lg:w-2/6 rounded-lg p-10 flex items-center flex-col">
        <img src="/goobies-logo.png" alt="" className="pb-8" />
        <h1 className="font-bold text-center pb-8 text-xl">
          Log in to continue
        </h1>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form
              className="w-full flex flex-col gap-3"
              onSubmit={handleSubmit}
            >
              <Input
                name="email"
                type="email"
                placeholder="Enter Email"
                Icon={<PersonIcon className="h-5 w-5" />}
              />
              <Input
                name="password"
                type="password"
                placeholder="Enter Password"
                Icon={<LockClosedIcon className="h-5 w-5" />}
              />
              <Button
                type="submit"
                text="Login"
                variant="primary"
                disabled={isSubmitting}
              />
            </form>
          )}
        </Formik>
        <div className="flex items-center justify-center w-full pt-8 gap-5">
          <Link to="/forgot-password" className="text-sm">
            Can't log in?
          </Link>
          <div className="bg-gray-400 h-1 w-1 rounded-full"></div>
          <Link to="/sign-up" className="text-sm">
            Create an account
          </Link>
        </div>
      </div>
    </section>
  )
}

export default Login
