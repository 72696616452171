import { Link } from "react-router-dom"
import {
  BiSolidBabyCarriage,
  BiSolidInfoCircle,
  BiLogOutCircle,
  BiSolidUserCircle,
} from "react-icons/bi"

interface AccountTooltipProps {
  handleLogout: () => void
  isLoggedIn: boolean
}

const AccountTooltip = ({ handleLogout, isLoggedIn }: AccountTooltipProps) => {
  return (
    <div className="group relative cursor-pointer">
      <Link to={isLoggedIn ? "/profile" : "/sign-in"}>
        <BiSolidUserCircle className="w-5 h-5" />
      </Link>
      {isLoggedIn && (
        <div
          className="w-40 py-3 bg-white rounded-md drop-shadow-md absolute top-8 left-1/2 transform -translate-x-1/2  opacity-0 invisible group-hover:visible group-hover:opacity-100
            transition-all duration-500 ease-in-out cursor-default
          "
        >
          <h5 className="font-semibold text-sm pl-3">My Account</h5>
          <hr className="mt-3" />
          <ul className="flex flex-col w-full text-left">
            <li className="text-sm pl-3 hover:bg-gray-200 py-3 transition-colors duration-200 ease-in-out">
              <Link
                to="/profile?tab=bookings"
                className="flex items-center gap-2"
              >
                <BiSolidBabyCarriage />
                My Bookings
              </Link>
            </li>
            <li className="text-sm pl-3 hover:bg-gray-200 py-3 transition-colors duration-200 ease-in-out">
              <Link
                to="/profile?tab=details"
                className="flex items-center gap-2"
              >
                <BiSolidInfoCircle />
                My Details
              </Link>
            </li>
            <li className="text-sm pl-3 hover:bg-gray-200 py-3 transition-colors duration-200 ease-in-out">
              <button
                className="flex items-center gap-2"
                onClick={handleLogout}
              >
                <BiLogOutCircle />
                Sign Out
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default AccountTooltip
