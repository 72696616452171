import { useParams, useNavigate } from "react-router-dom"
import useFetch from "../hooks/useFetch"
import useProtectedRoute from "../hooks/useProtectedRoute"
import { Loader } from "../components"
import { CalendarIcon, ClockIcon } from "@radix-ui/react-icons"
import { FaArrowLeft } from "react-icons/fa6"

const BookingDetails = () => {
  useProtectedRoute()
  const navigate = useNavigate()
  const { id } = useParams()

  const { data: booking, loading: bookingLoading } = useFetch({
    url: `/bookings/${id}`,
  })

  return (
    <section className="min-h-screen py-24 lg:px-20 px-5">
      {bookingLoading ? (
        <Loader />
      ) : (
        booking && (
          <div className="h-full grid grid-cols-12 grid-rows-2 gap-5">
            <div className="col-span-8 bg-white drop-shadow p-5">
              <button
                onClick={() => navigate(-1)}
                className="flex items-center gap-3"
              >
                <FaArrowLeft />
                <span className="text-sm">Go back</span>
              </button>
              <div className="w-full flex flex-col items-center justify-center">
                <div className="bg-white w-full rounded-lg px-10 p-5">
                  <div className="pb-3 flex items-center justify-between">
                    <h1 className="font-bold text-2xl lg:text-2xl">Address</h1>
                  </div>
                  <p className="text-sm break-normal text-gray-600">
                    {booking?.street}, {booking?.suburb}, {booking?.city},{" "}
                    {booking?.province}, {booking?.postalCode}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-4 bg-primary-pink rounded-md p-5 flex flex-col gap-5 row-span-2">
              <div>
                <div className="flex items-center gap-5">
                  <div className="aspect-square w-20 overflow-hidden rounded-full drop-shadow bg-dark-pink">
                    <img
                      src={booking.babysitterImage}
                      alt=""
                      className="object-contain"
                    />
                  </div>
                  <h1 className="font-bold text-xl lg:text-xl text-center">
                    {booking.babysitter}
                  </h1>
                </div>
              </div>
              <div className="w-full flex flex-col items-center justify-center">
                <div className="bg-white w-full rounded-lg px-10 p-5">
                  <h1 className="font-bold text-lg lg:text-lg pb-3">Details</h1>
                  <div className="flex items-center gap-4 pb-2">
                    <CalendarIcon className="w-4 h-4" />
                    <p className="text-sm">{booking.date}</p>
                  </div>
                  <div className="flex items-center gap-4">
                    <ClockIcon className="w-4 h-4" />
                    <p className="text-sm">
                      {booking.startTime}-{booking.endTime}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col items-center justify-center">
                <div className="bg-white w-full rounded-lg px-10 p-5 flex flex-col gap-1">
                  <h1 className="font-bold text-lg lg:text-lg pb-2">Summary</h1>
                  <div className="flex items-center justify-between text-sm">
                    <p>Price per hour:</p>
                    <p>R95.00</p>
                  </div>
                  <div className="flex items-center justify-between text-sm">
                    <p>Total hours:</p>
                    <p>{booking?.totalHours}</p>
                  </div>
                  <div className="flex items-center justify-between text-sm">
                    <p>Travel distance:</p>
                    <p>{booking?.travelDistance}km</p>
                  </div>
                  <div className="flex items-center justify-between text-sm">
                    <p>Travel cost:</p>
                    <p>R{booking?.travelCost}</p>
                  </div>
                  <hr />
                  <div className="flex items-center justify-between py-1 font-semibold text-lg">
                    <p>Total</p>
                    <p>R{booking.paymentAmount}</p>
                  </div>
                  <hr />
                  <div className="flex items-center justify-between py-1 font-semibold text-lg">
                    <p>Deposit</p>
                    <p>R{booking.depositAmount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </section>
  )
}

export default BookingDetails
