import { useState } from "react"

type BabysitterTabsProps = {
  description: string
  reviews: string[]
}

const BabysitterTabs = ({ description, reviews }: BabysitterTabsProps) => {
  const [activeTab, setActiveTab] = useState<string>("description")

  return (
    <div className="px-5 py-10 lg:p-20">
      <div className="flex items-center justify-around">
        <button
          className={`${
            activeTab === "description"
              ? "bg-gray-200 cursor-default"
              : "bg-white cursorpointer"
          } w-full text-center py-3 border-b border-b-gray-200`}
          onClick={() => setActiveTab("description")}
        >
          Description
        </button>
        <button
          className={`${
            activeTab !== "description"
              ? "bg-gray-200 cursor-default"
              : "bg-white cursorpointer"
          } w-full text-center py-3 border-b border-b-gray-200`}
          onClick={() => setActiveTab("reviews")}
        >
          Reviews
        </button>
      </div>
      <div className="p-5">
        <p>
          {activeTab === "description" ? description : reviews?.join("\n\n")}
        </p>
      </div>
    </div>
  )
}

export default BabysitterTabs
