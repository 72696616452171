import { useState, useEffect, useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { BookingContext } from "../components/context"
import { Loader } from "../components"
import axiosInstance from "../helpers/axios.instance"
import useProtectedRoute from "../hooks/useProtectedRoute"

const Submit = () => {
  useProtectedRoute()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const { booking } = useContext(BookingContext)

  const submitBooking = useCallback(async () => {
    try {
      setLoading(true)

      const res = await axiosInstance.post("/bookings", booking)

      const bookingId = res.data.id

      setLoading(false)
      navigate(`/checkout/${bookingId}`)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }, [booking, navigate])

  useEffect(() => {
    if (booking) {
      submitBooking()
    } else {
      navigate("/babysitters")
    }
  }, [booking, submitBooking, navigate])

  return <section className="p-20">{loading && <Loader />}</section>
}

export default Submit
